// Import necessary modules and assets
import React, { useState, useEffect } from 'react';
import './App.css';
import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as PhoneIcon } from './assets/phone.svg';
import { ReactComponent as MapPinIcon } from './assets/mappin.svg';

const TARGET_DATE = new Date('2024-11-15T00:00:00').getTime();

// Utility function to calculate time left until target date
const calculateTimeLeft = () => {
  const now = new Date().getTime();
  const difference = TARGET_DATE - now;

  return {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((difference % (1000 * 60)) / 1000),
  };
};

// Countdown item component for displaying individual time units
const CountdownItem = ({ value, label }) => (
  <div className="time">
    <span>{value}</span>
    <div className="label">{label}</div>
  </div>
);

function App() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="App">
      <header className="App-header">

        <h1>SAN PEDRO</h1>
        <h2>BEACH RESORT</h2>
        <h6>For over 40 years, San Pedro Beach Resort has been a cherished destination along the Opol coastline. With its warm Filipino hospitality and inviting atmosphere, it’s a favorite spot for families, couples, and travelers to relax and enjoy the natural beauty of the sea.</h6>

        <p className="contact">
          <span className="icon"><PhoneIcon /></span> +63 922 899 4466
        </p>
        <p className="location">
          <span className="icon"><MapPinIcon /></span> Zone 2, Taboc, Opol, Misamis Oriental, Philippines
        </p>
        <p className="announcement">Website launching soon!</p>

        {/* Countdown Timer Display */}
        <div className="countdown">
          <CountdownItem value={timeLeft.days} label="DAYS" />
          <CountdownItem value={timeLeft.hours} label="HRS" />
          <CountdownItem value={timeLeft.minutes} label="MIN" />
          <CountdownItem value={timeLeft.seconds} label="SEC" />
        </div>
        <p className="footer">Made with ❤ in</p>


      </header>
    </div>
  );
}

export default App;
